

/**
 * @param axios
 * @param config
 */
export default (axios, config = {}) => {

  const defaultConfig = {
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // 'Content-Type': 'multipart/form-data',
      'custom-defined-header-key': 'custom-defined-header-value',
      // 自定义请求头：对所有请求方法生效
      common: {
        // 'token': store.state.token,
      },
      // 自定义请求头：只对post方法生效
      post: {
        'post-custom-key': 'custom value: only for post method'
      },
      // 自定义请求头：只对get方法生效
      get: {
        'get-custom-key': 'custom value: only for get method'
      }
    }
  }

  Object.assign(axios.defaults, defaultConfig, config)
  return axios
}
